import { FOOTER } from "@const/shared"
import { Container } from "@components/wrapper"
import { Disclosure, Transition } from "@headlessui/react"
import React from "react"
import { AiOutlineRight } from "react-icons/ai"
import Socmed from "./Socmed"
import Image from "next/image"
import { HARISENIN_LOGO } from "@constants/const/pictures"
import Link from "next/link"

const LINK_MENU = [
  {
    title: "Program",
    child: [
      { label: "Bootcamp", url: "/school/bootcamp" },
      // { label: "ProClass", url: "/school" },
      { label: "Video Course", url: "/learning/category" },
      { label: "Event & Workshop", url: "/risebinar" },
      { label: "Digital Produk", url: "/digital-product" },
    ],
  },
  {
    title: "Corporate",
    child: [
      { label: "Corporate Training", url: "/corporate-training" },
      { label: "Hire Graduates", url: "/hire-graduates" },
    ],
  },
  {
    title: "About",
    child: [
      { label: "Tentang Kami", url: "/tentang-kami" },
      { label: "Alumni", url: "/alumni" },
      { label: "Karir", url: "https://karir.harisenin.com" },
      { label: "Blog", url: "https://tipssukses.harisenin.com" },
    ],
  },
  {
    title: "Bantuan",
    child: [
      { label: "FAQ", url: "/faq" },
      { label: "Syarat & Ketentuan", url: "/syarat-ketentuan" },
      { label: "Kebijakan Privasi", url: "/kebijakan-privasi" },
      { label: "Kebijakan Garansi", url: "/kebijakan-garansi" },
    ],
  },
]

function Footer() {
  return (
    <footer className="bg-grey-f9">
      <Container className="py-6 md:py-12">
        <div className="flex flex-col md:flex-row gap-4 md:gap-16">
          <div className="md:w-1/3">
            <Link href="/" passHref={true}>
              <Image
                src={HARISENIN_LOGO}
                alt="harisenin.com logo"
                className="h-[25px] w-auto"
                width={100}
                height={25}
              />
            </Link>
            <p className="text-sm md:text-base mt-4">
              Karena kesuksesan ialah hak setiap anak bangsa, harisenin.com berambisi untuk
              memberikan akses pendidikan yang berkualitas dan terjangkau pada generasi muda
              Indonesia.
            </p>
          </div>

          <div className="flex flex-col md:flex-row w-full md:w-[60%] justify-around text-sm md:text-base">
            {LINK_MENU.map((value, index) => (
              <div key={index}>
                <div className="flex flex-col my-2 md:my-0 md:hidden">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={`flex justify-between font-bold w-full text-left mb-2`}
                        >
                          {value.title}
                          <AiOutlineRight
                            className={`${open ? "rotate-90 transform" : ""} md:hidden`}
                          />
                        </Disclosure.Button>
                        <Transition
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          {value.child.map((item, i) => (
                            <Disclosure.Panel key={i}>
                              <Link href={item.url} className="my-2">
                                {item.label}
                              </Link>
                            </Disclosure.Panel>
                          ))}
                        </Transition>
                      </>
                    )}
                  </Disclosure>
                </div>
                <div className=" flex-col w-full hidden md:flex">
                  <h2 className="font-bold mb-2">{value.title}</h2>
                  {value.child.map((v, i) => (
                    <a href={v.url} key={i} className="my-1">
                      {v.label}
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <h2 className="flex justify-between items-center font-bold md:hidden -mb-2 text-sm md:text-base">
            Ikuti Kami{" "}
            <div className="sm:hidden">
              <Socmed />
            </div>
          </h2>
        </div>
        <div className="flex justify-between border-t border-grey-ec mt-4 pt-4">
          <p className="mt-4 text-sm md:text-base">
            © 2020-2023 PT. Karya Kaya Bahagia. All rights reserved.
          </p>
          <div className="sm-only:hidden">
            <Socmed />
          </div>
        </div>
      </Container>
    </footer>
  )
}
export const HariseninFooter = () => {
  return <Footer />
}
