import { FormInput } from "@components/input/react-hook-form/FormInput"
import { Spinner } from "@components/misc"
import { Modal } from "@components/modal"
import { FlexBox } from "@components/wrapper"
import { HARISENIN_PUBLIC_LOGO } from "@constants/const/pictures"
import { flex_center } from "@constants/styles"
import { yupResolver } from "@hookform/resolvers/yup"
import { GoogleLoginData } from "@interfaces/auth"
import AuthServices from "@lib/api/services/auth.services"
import { useAnalytics } from "@lib/context/AnalyticsContext"
import { calculateAge } from "@lib/functions"
import { resetValidationSchema } from "@modules/auth/const/validation"
import { useGoogleLogin } from "@react-oauth/google"
import clsx from "clsx"
import ky from "ky"
import Image from "next/image"
import { ButtonHTMLAttributes, useState } from "react"
import { useForm } from "react-hook-form"
import { RegularButton } from "."

export interface GoogleLoginProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  redirect?: string
  handleError: (a: string) => void
  copyText: string
  onNewUser?: () => void
}

export const GoogleLoginButton = ({
  redirect,
  handleError,
  copyText,
  onNewUser,
}: GoogleLoginProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [tokenResponse, setTokenResponse] = useState<any>(null)
  const [userData, setUserData] = useState<GoogleLoginData | null>(null)

  const [isPasswordPopup, setIsPasswordPopup] = useState(false)
  const [isSubmittingPassword, setIsSubmittingPassword] = useState(false)
  const [isAgree, setIsAgree] = useState(false)

  const auth = new AuthServices()
  const { analytics } = useAnalytics()

  const { handleSubmit, control } = useForm({
    mode: "onSubmit",
    // @ts-ignore
    resolver: yupResolver(resetValidationSchema),
    defaultValues: {
      new_password: "",
      password_confirmation: "",
    },
  })


  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse)
      setTokenResponse(tokenResponse)
    },
  })

  async function handleGoogleLogin(response: any) {
    // const userProfile = response.profileObj
    // const userFullname = userProfile.name
    // const userEmail = userProfile.email
    // const googleId = userProfile.googleId
    login()

    onNewUser && onNewUser()

    setIsSubmitting(true)

    try {
      handleError("")

      const resGoogle = await ky.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
            Accept: "application/json",
          },
        }
      )

      const data = (await resGoogle.json()) as any

      const userFullname = data.name
      const userEmail = data.email
      const googleId = data.id


      const res = await auth.googleLogin({
        email: userEmail,
        fullName: userFullname,
        googleId,
      })

      if (res.isFailure) {
        setIsSubmitting(false)
        const errorMessage = res.error.message
        return handleError(errorMessage)
      }

      const result = res.getValue()

      const tokenData = result as GoogleLoginData
      setUserData(tokenData)

      if (!tokenData.new_user) {
        analytics.identify(tokenData.id, {
          address: {
            city: "",
            country: "Indonesia",
            postalCode: "",
            state: "",
          },
          age: calculateAge(tokenData.user_birthday as Date),
          avatar: tokenData.user_picture,
          birthday: new Date(tokenData.user_birthday as Date),
          email: tokenData.user_email,
          firstName: tokenData.user_firstname,
          gender: result.user_gender == 1 ? "Male" : "Female",
          lastName: tokenData.user_lastname,
          name: tokenData.fullname,
          phone: result.user_phone_number,
        })

        if (!redirect) {
          window.location.reload()
        } else {
          window.location.assign(redirect)
        }
      } else {
        onNewUser && onNewUser()
        setIsPasswordPopup(true)
      }

      analytics.track("Signed In", {
        method: "email",
      })
    } catch (error: any) {
      setIsSubmitting(false)
      handleError("Terjadi kesalahan. Silahkan coba lagi")
    }
  }

  function handleGoogleError(res: any): void {
    console.log(res)
    // console.log(res)
  }

  async function onSubmit(value: any) {
    if (!userData) {
      return
    }

    setIsSubmittingPassword(true)
    try {
      const res = await auth.submitGooglePassword({
        email: userData.user_email,
        password: value.new_password,
        fullname: userData.fullname,
        user_gender: userData.user_gender ?? 0,
        // @ts-ignore
        user_birthday: userData.user_birthday ?? null,
      })

      if(res.isSuccess) {
        if (!redirect) {
          window.location.reload()
        } else {
          window.location.assign(redirect)
        }
      }

      
    } catch (error) {
      console.log(error)
      return setIsSubmittingPassword(false)
    }
  }


  return (
    <button
      disabled={isSubmitting}
      onClick={handleGoogleLogin}
      className={clsx(
        flex_center,
        "relative px-4 py-[20px] h-[36px] text-grey-69 w-full border border-grey-69 gap-2"
      )}
    >
      {isSubmitting ? (
        <Spinner />
      ) : (
        <>
          <Image
            width={100}
            height={100}
            src={`${HARISENIN_PUBLIC_LOGO}/logo_google.png`}
            alt="google"
            className="w-6 h-6"
          />
          <span className="sm-only:text-sm">{copyText}</span>
        </>
      )}
    </button>
  )

  // return (
  //   <>
  //     <GoogleLogin
  //       clientId={process.env.GOOGLE_CLIENT_ID || process.env.HARISENIN_GOOGLE_CLIENT_ID || ""}
  //       render={(renderProps) => (
  //         <button
  //           disabled={isSubmitting}
  //           onClick={renderProps.onClick}
  //           className={clsx(
  //             flex_center,
  //             "relative px-4 py-[20px] h-[36px] text-grey-69 w-full border border-grey-69 gap-2"
  //           )}
  //         >
  //           {isSubmitting ? (
  //             <Spinner />
  //           ) : (
  //             <>
  //               <Image
  //                 width={100}
  //                 height={100}
  //                 src={`${HARISENIN_PUBLIC_LOGO}/logo_google.png`}
  //                 alt="google"
  //                 className="wh-6"
  //               />
  //               <span className="sm-only:text-sm">{copyText}</span>
  //             </>
  //           )}
  //         </button>
  //       )}
  //       buttonText="Login"
  //       onSuccess={handleGoogleLogin}
  //       onFailure={handleGoogleError}
  //       cookiePolicy={"single_host_origin"}
  //     />

  //     <Modal
  //       show={isPasswordPopup}
  //       onClose={() => null}
  //       contentClassName="sm:min-w-[540px] !rounded-xl "
  //       contentPadding="!p-0"
  //       zIndex="!z-[1005]"
  //       customCloseButton={<></>}
  //     >
  //       <FlexBox justify="end" className="sm:p-6 px-6 py-4">
  //         {/* <button className="sm-only:">
  //           <FaTimes />
  //         </button> */}
  //       </FlexBox>

  //       <div className="px-[42px] grid gap-1">
  //         <h2 className="sm:text-4xl text-xl text-primary-black text-center font-bold">
  //           Masukkan Password
  //         </h2>
  //         <div className="text-secondary-grey text-center sm-only:text-sm">
  //           Yuk, masukkan password untuk buat akun baru kamu.
  //         </div>
  //       </div>

  //       <form onSubmit={handleSubmit(onSubmit)} className="sm:px-[42px] px-6 py-6 grid gap-6">
  //         <div className="grid gap-4">
  //           <FormInput
  //             control={control}
  //             name="new_password"
  //             type="password"
  //             theme="blue"
  //             label="Password"
  //           />
  //           <FormInput
  //             control={control}
  //             name="password_confirmation"
  //             type="password"
  //             theme="blue"
  //             label="Konfirmasi Password"
  //           />
  //         </div>

  //         <div className={clsx("checkbox flex text-sm")}>
  //           <input
  //             type="checkbox"
  //             id="agreement-google"
  //             name="agreement-google"
  //             onClick={() => setIsAgree(!isAgree)}
  //           />
  //           <label htmlFor="agreement-google">
  //             Saya sudah membaca dan menyetujui{" "}
  //             <a
  //               href={"/syarat-ketentuan"}
  //               target="_blank"
  //               rel="noreferrer"
  //               className="text-new-blue underline"
  //             >
  //               Syarat dan Ketentuan
  //             </a>{" "}
  //           </label>
  //         </div>

  //         <RegularButton
  //           isSubmitting={isSubmittingPassword}
  //           isFormik
  //           id="btn-input-password"
  //           className="w-full"
  //           customPadding="py-2.5"
  //           disabled={!isAgree}
  //           variant="new-blue"
  //         >
  //           Daftar
  //         </RegularButton>
  //       </form>
  //     </Modal>
  //   </>
  // )
}
